import uc from "../assets/underconstruction.png"
import "./under-construction.scss"
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


const UnderConstruction = ()=>{
    return (
        <>
            <div className="under-construction">
                <div className="left">
                    <h1 className="h1title">We are rebranding</h1>
                    <p className="body">Exciting changes are on the horizon. Our platform is being reimagined to focus on recruiting top talent and connecting businesses with the perfect candidates. Stay tuned for a cutting-edge experience in hiring, powered by AI and designed to meet your recruitment needs. Coming soon!</p>
                    <button className="ctabtn"> Learn more</button>
                    <div className="socials d-flex">
                        <FaLinkedin  color="#909090"  className="icon mx-2" size={30}/>
                        <FaInstagram color="#909090" className="icon mx-2" size={30}/>
                        <FaFacebookF  color="#909090"  className="icon mx-2"size={30}/>
                    </div>
                </div>
                <img src={uc} alt="Under Construction" className="img"/>
            </div>
        </>
    );
}

export default UnderConstruction;